import React from "react"
import { graphql } from "gatsby"
import BlogPage from "../components/blog-page"
import useSeoContent from "../hooks/use-seo-content"
import SEO from "../components/seo"
import { filenameToTitle } from "../utils"

export default function BlogList({ data, location }) {
  const { items } = data
  const seo = useSeoContent()

  items.edges = items.edges.map(edge => {
    edge.node.frontmatter.thumbnailAlt = filenameToTitle(
      edge.node.frontmatter.thumbnail.name
    )
    return edge
  })

  return (
    <>
      <SEO
        location={location}
        title={seo.work_meta_title}
        description={seo.work_meta_description}
        featuredImage={seo.work_featured_image}
      />
      <BlogPage title={"Work"} items={items.edges} />
    </>
  )
}

export const query = graphql`
  query workListQuery {
    items: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "work" } }
        frontmatter: { published: { ne: false } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            excerpt
            date
            video
            thumbnail {
              name
              childImageSharp {
                fixed(width: 400) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
          excerpt
          timeToRead
        }
      }
    }
  }
`
